/* eslint-disable no-undef */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {  LandingFooterPanel, Layout, NavigationBarV2 } from '../../components';

import { Section, Header, ContentWrapper, SmallHeader, ParagraphWrapper, OList, List } from './styles';

import { checkPermission } from '../../utils/methods';

const certainDefinitions = [
  {
    title: '"Affiliate"',
    content: 'means Customer, its subsidiaries and any other entity specifically designated by Customer that directly or indirectly controls or is under common control with Customer. “Control,” for purposes of this definition, means direct or indirect ownership or control of more than 50% of the voting interests of the subject entity.',
  },
  {
    title: '"Company Data"',
    content: 'means any data provided by Customer or its Users for use with the Woliba Software Service that pertains to or relates to Customer\'s Users.',
  },
  {
    title: '"Designated Business Unit(s)" or "DBU(s)"',
    content: 'means Customer’s business unit(s) or entity(ies) specified in an Order (as they may be re- named or re-organized) that are authorized by Woliba to use the Ordered Software Service (defined below), and for whose benefit the Ordered Software Service may be used, in accordance with the terms of this Agreement. Customer shall be responsible for ensuring compliance of the Agreement by each such DBU, and Customer shall be responsible for any breach of the Agreement by each such DBU.',
  },
  {
    title: '"Data Protection Laws"',
    content: 'means all data protection and privacy laws, rules and regulations applicable to a party and binding on that party in the performance of its obligations under this Agreement, including, where applicable, EC Directive 2002/58/EC and Regulation 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (General Data Protection Regulation or “GDPR”).',
  },
  {
    title: '"Documentation"',
    content: 'with respect to a particular Woliba Software Service, means the guides and user manuals relating to that Woliba Software Service, as well as the relevant Services Description (defined below) attached to the applicable Order.',
  },
  {
    title: '"Fees"',
    content: 'means the Subscription Fees, Professional Services Fees, Start-Up Fees and any other fees due and payable under or in connection with this Agreement.',
  },
  {
    title: '"Highly-Sensitive Personal Information"',
    content: 'means an (a) individual’s government-issued identification number (including social security number, driver’s license number or state-issued identified number); (b) financial account number, credit card number, debit card number, credit report information, with or without any required security code, access code, personal identification number or password, that would permit access to an individual’s financial account; or (c) biometric or health data.',
  },
  {
    title: '"Order"',
    content: 'means the order schedule pursuant to which Customer orders a Woliba Software Service and any related Professional Services.',
  },
  {
    title: '"Personal Information"',
    content: 'means information provided to Woliba by or at the direction of Customer or its Users, or access to which was provided to Woliba by or at the direction of Customer or its Users in the course of Woliba’s performance under this Agreement: (a) that identifies or can be used to identify an individual (including, without limitation, names, signatures, addresses, telephone numbers, e-mail addresses and other unique identifiers); (b) that can be used to authenticate an individual (including, without limitation, employee identification numbers, government-issued identification numbers, passwords or PINs, financial account numbers, credit report information, biometric or health data, answers to security questions and other personal identifiers); or (c) if Customer is located in the European Economic Area (“EEA”), that constitutes “Personal Data” under the GDPR. Customer’s business contact information is not by itself deemed to be Personal Information.',
  },
  {
    title: '"Professional Services"',
    content: 'mean the set-up, implementation, consulting and training services to be provided by Woliba in accordance with one or more Statements of Work (each an “SOW”) that are signed by the parties from time to time pursuant to this Agreement.',
  },
  {
    title: '"Professional Services Fees"',
    content: 'mean the fees payable for the Professional Services, as outlined in the applicable SOW or Order.',
  },
  {
    title: '"Results"',
    content: 'means reports, documents, analytical views, and price guidance generated by a Woliba Software Service using the Company Data.',
  },
  {
    title: '"Start-Up Fee"',
    content: 'means a one-time, non-recurring fee identified in a particular Order that covers a portion of Woliba’s up-front costs associated with setting up and provisioning the software environment(s) for the Ordered Software Service(s), including, but not limited to: (a) setting-up of internal Woliba systems, (b) procuring required third party licenses used in the production environment, (c) covering non-recurring internal production operations costs and external (i.e., Amazon Web Services or “Assigned Workers”) set-up costs, and (d) setting up certain project controls.',
  },
  {
    title: '"Services"',
    content: 'means, collectively, the Professional Services, Technical Support Services and SaaS Delivery Services (all as defined below).',
  },
  {
    title: '"Subscription Fee"',
    content: 'means the recurring fee identified in a particular Order that is paid in consideration for the right to access a particular Ordered Software Service during the Subscription Term within the scope of use outlined in such Order.',
  },
  {
    title: '"User"',
    content: 'means a Customer user who is properly authorized as a user of the applicable Woliba Software Service. Users are limited to employees of Customer and consultants of Customer solely with respect to their use of a Woliba Software Service on behalf of Customer; provided that: (a) any such consultant is not a competitor of Woliba; (b) any such consultant is informed of the confidential status of the Woliba Confidential Information; (c) any such consultant has agreed in writing to be bound by confidentiality provisions at least as restrictive as those contained herein, and (d) Customer agrees to be liable for any breach by its consultants to the same extent as if such breach was committed by Customer.',
  },
  {
    title: '"Visual Analytics User,"',
    content: 'if identified in an applicable Order, means a User for a Woliba Software Service that has the right to run reports and dashboards to perform investigative analysis and view data online.',
  },
  {
    title: '"Woliba Software Service"',
    content: 'means a proprietary Woliba software solution provided on a software-as-a-service (SaaS) basis.',
  },
];

const orderingProcess = [
  {
    title: 'Ordering Process',
    content: 'From time to time during the Term (as defined below), Customer and its Affiliates may subscribe to one or more Woliba Software Services by entering into an Order that sets forth the terms under which Woliba will make the Woliba Software Service(s) available to Customer and its Users. By execution of an Order, Affiliates shall be considered to be a Customer for purposes of this Agreement and shall be bound by all of the terms and conditions hereof.',
  },
  {
    title: 'Order',
    content: 'Each Order shall set forth the Woliba Software Service(s) being subscribed to (each, an “Ordered Software Service”), the term of the subscription (the “Subscription Term”), the usage allowances including the DBU(s) (if applicable), any Professional Services to be provided, the Fees payable under such Order, and any other terms to which the parties may agree. Each Order shall incorporate the terms of this Agreement and shall be deemed a part hereof. In conjunction with the agreement to an Order, Woliba shall provide Customer with the applicable Services Description (each, a “Services Description”) for the Ordered Software Service subscribed to therein. Any terms and conditions of any purchase orders or acknowledgements that are inconsistent with or in addition to the terms and conditions of this Agreement and an executed Order will not apply.',
  },
];

const subscriptionServices = [
  {
    title: 'Software Service',
    content: 'Subject to the terms and conditions of this Agreement, Woliba will make Ordered Software Service(s) available to Customer and its Users only (a) during the relevant Subscription Term specified in the applicable Order, (b) by the authorized number of Users, if applicable, (c) subject to the usage allowances, including the DBU(s) (if applicable), and (d) for the Users’ personal use and the Customer’s internal business purposes and solely for the purpose intended by the specific subscription purchased for the Woliba Software Service, as set forth in the applicable Documentation. Unless otherwise expressly set forth in an Order, the Woliba Software Services are provided solely for the benefit of the DBU(s) listed in the Order and its Users, and not, by implication or otherwise, to any other division or department of Customer, or any parent, subsidiary or affiliate of Customer or any of their respective employees or purported Users.',
  },
  {
    title: 'Professional Services',
    content: 'Woliba will provide the Professional Services set forth in the applicable Statement of Work (“SOW”). Professional Services Fees associated with SOWs signed concurrently with a particular Order also may be listed in the Order itself. All SOWs will incorporate and be subject to the terms of this Agreement. Customer acknowledges that the provision of Professional Services by Woliba is dependent on Customer providing reasonable access to relevant resources and providing timely decisions and input in connection with those Professional Services.',
  },
  {
    title: 'Technical Support',
    content: 'The description of technical support (the “Technical Support Services”) provided by Woliba for each Woliba Software Service is set forth in Schedule B attached hereto.',
  },
  {
    title: 'Hosting and Delivery',
    content: 'Woliba will host and maintain each Woliba Software Service and the other software and other technology that enables the provision of the Woliba Software Services (collectively, the “Software”) on servers operated and maintained by or at the direction of Woliba in accordance with the terms contained herein and in Schedule C attached hereto (the “SaaS Delivery Services”). Woliba may delegate the performance of certain portions of the SaaS Delivery Services to third parties, provided Woliba remains responsible to Customer for the delivery of the Ordered Software Service(s). Without limiting the generality of the foregoing, it is understood that Woliba engages third party provider(s) to host the Ordered Software Services and the Company Data. Woliba may change its hosting services provider in its sole discretion; provided that (i) it provides 30 days’ advance written notice to Customer (with email notification being acceptable), and (ii) any new hosting services provider shall have a current SOC 1 Type II audit conducted under SSAE No. 16 (or successor audit standards) consisting of a comprehensive internal controls assessment report (a “Security Report”) covering the internal controls and information security related to its hosting services, prepared by a third party auditor, which such Security Report shall be made available to Customer for review prior to Woliba making any such change.',
  },
  {
    title: 'Third Party Products or Services',
    content: 'Third party technology that may be appropriate for use with certain Woliba Software Services may be specified in the Services Description attached to the relevant Order, as applicable. Woliba makes no warranty as to any third party software other than as expressly included in this Agreement. Customer’s use of any other third party products or services that are not licensed to Customer directly by Woliba (“Third Party Services”) shall be governed solely by the terms and conditions applicable to such Third Party Services, as agreed to between Customer and the third party. Woliba does not endorse or support, is not responsible for, and disclaims all liability with respect to Third Party Services, including without limitation, the privacy practices, data security processes or other policies related to Third Party Services. Customer releases and holds harmless Woliba with respect to any claim relating to Third Party Services.',
  },
  {
    title: 'Subcontracting',
    content: 'Woliba retains the right to subcontract any or all of the Services to be performed under this Agreement to a qualified subcontractor of Woliba’s choosing; provided that: (a) such subcontractor is not a competitor of Customer; (b) such subcontractor is informed of the confidential status of the Customer Confidential Information; (c) such subcontractor has agreed in writing to be bound by confidentiality provisions at least as restrictive as those contained herein, and (d) Woliba agrees to be liable for any breach by its subcontractors to the same extent as if such breach was committed by Woliba. Without limiting the generality of the foregoing, Customer acknowledges and agrees that Woliba may use as subcontractors AWS and any other third party providers described in this Agreement, its Schedules, Orders and/or SOWs.',
  },
];

const feesPaymentTerms = [
  {
    title: 'Start-Up and Subscription Fees',
    content: 'Customer will pay Woliba the Start-Up Fee(s) and the Subscription Fee(s) listed on each Order in accordance with the terms contained therein. Subscription Fees will be invoiced and paid in consideration for access to the applicable Ordered Software Service(s) and for the related SaaS Delivery Services and Technical Support Services.',
  },
  {
    title: 'Professional Services',
    content: 'Woliba will provide the Professional Services set forth in the applicable SOW. Professional Services Fees associated with SOWs signed concurrently with a particular Order also may be listed in the Order itself. All SOWs will incorporate and be subject to the terms of this Agreement. Customer acknowledges that the provision of Professional Services by Woliba is dependent on Customer providing reasonable access to relevant resources and providing timely decisions and input in connection with those Professional Services.',
  },
  {
    title: 'Payment Terms',
    content: 'Unless otherwise set forth in the applicable Order or SOW, payment from Customer is due within thirty (30) days from Customer’s receipt of invoice.',
  },
  {
    title: 'Suspension',
    content: 'In the event Customer fails to pay an undisputed invoice within 30 days from the date the invoice is received, Woliba may suspend access to the Ordered Software Services and the provision of the Services by providing written notice to Customer, with such suspension to be effective 30 days from Customer’s receipt of such notice unless Customer pays all undisputed invoices before such date. A suspension under this Section 4.4 will not constitute a termination of the Agreement or the relevant Order, nor will it relieve Customer of any of its obligations or liabilities under the Agreement or the relevant Order.',
  },
  {
    title: 'Taxes',
    content: 'The Fees set forth in the Order or any SOW do not include taxes unless stated otherwise. Customer will pay and indemnify and hold Woliba harmless for, all applicable sales, use, VAT, excise, import, export, property, withholding or similar taxes or duties in connection with the provision of access to the Woliba Software Services and related services to Customer by Woliba not based on Woliba’s net income, including any interest or penalties thereon resulting from Customer’s failure to pay such taxes or other charges on a timely basis (“Taxes”). If Customer is required to pay or withhold any Taxes in respect of any payments due to Woliba hereunder, Customer will gross up payments actually made such that Woliba receives sums due hereunder in full and free of any deduction for any such Taxes. Customer hereby confirms that Woliba can rely on the address set forth in this Agreement as being the place of use and benefit of the subscription and services provided by Woliba for sales tax purposes. If Customer is legally entitled to an exemption from the payment of any Taxes, Customer will promptly provide Woliba with legally sufficient tax exemption certificates for each taxing jurisdiction and tax for which it claims exemption.',
  },
  {
    title: 'Travel and Incidental Expenses',
    content: 'Customer will reimburse Woliba for any reasonable out-of-pocket expenses incurred by Woliba in connection with performing any pre-approved on-site activities at Customer location. Woliba agrees to incur any such expenses in accordance with the principles contained in Customer’s corporate travel policy to the extent such has been provided to Woliba in writing prior to the Effective Date.',
  },
];

const customerObligations = [
  {
    title: 'Equipment',
    content: 'Customer is responsible for obtaining and maintaining any equipment and ancillary services needed to access the Woliba Software Service(s) having the minimum configuration specified from time to time by Woliba (the “Access Equipment”).',
  },
  {
    title: 'Company Data',
    content: 'Customer understands that, in order to utilize certain Woliba Software Services, it may have to provide Woliba with certain data, the content and format of which will conform to Woliba’s data input and output specifications. The specifications may be subject to customization by mutual agreement based on Customer’s specific needs and operations. Customer is solely responsible for the legality, integrity, accuracy, quality, and content of the Company Data, and Woliba will not be liable to Customer for any failure to provide a Woliba Software Service resulting from inaccurate or incomplete Company Data or other information provided by or on behalf of Customer to Woliba. To the extent any Personal Information is included in Company Data, Customer is fully responsible for providing any notices and obtaining any consents required prior to providing any Personal Information to Woliba including those related to the collection, use, processing, transfer and disclosure of personal data. Customer represents and warrants that all such consents have been or will be obtained.',
  },
  {
    title: 'Passwords and User Accounts',
    content: 'Customer shall be responsible for each of its Users keeping his or her account name and password confidential and for all activity occurring under its User accounts. Customer shall abide by all applicable local, state, national and foreign laws, treaties and regulations in connection with its use of any Ordered Software Services, including all Data Protection Laws and those related to international communications and the transmission of technical or Personal Information. Customer shall notify Woliba immediately upon learning of any unauthorized use of a user account by a person that is not the User specified for such account. In the event of the unauthorized use of a Customer account as result of Customer’s or any of its Users’ failure to appropriately safeguard the user ID and/or password associated therewith, Customer will be responsible for all activities through the unauthorized use of such account, and Customer will indemnify and hold harmless Woliba for any claims, liability, damages, losses and costs (including reasonable attorneys\' fees) to the extent resulting from such use. Customer shall be responsible for the actions of its Users and agrees that all Users will be bound by the terms and conditions as set forth in this Agreement, the Privacy Policy and the applicable Order.',
  },
  {
    title: 'Compliance with Laws',
    content: 'Customer is solely responsible for ensuring that Company Data and Customer’s use of the Woliba Software Services provided to Customer will comply at all times with all applicable laws and regulations. Customer will not engage in any illegal activities or make or knowingly permit or facilitate any illegal or unauthorized use of Results or any information collected through the Woliba Software Services.',
  },
  {
    title: 'Prohibited Uses',
    content: 'Customer may not modify, rent, sublease, sublicense, assign, use as a service bureau, copy, lend, adapt, translate, sell, distribute, derive works from, disassemble, decompile or reverse engineer the Woliba Software Services or any Woliba intellectual property, or disclose in writing or orally the results of any system performance metrics of the Woliba Software Services to any third party, or use such results for its own competing software development activities, without the prior written consent of Woliba.',
  },
];

const ownershipAttribution = [
  {
    title: 'Customer Ownership',
    content: 'As between Customer and Woliba, Customer will retain all right, title and interest in and to the Company Data. Customer grants to Woliba a non-exclusive license to use, copy, store, modify and display the Company Data solely for the purpose of performing its obligations and enforcing its rights hereunder, including providing the Woliba Software Services and performing the Services, including fulfilling any obligations hereunder in connection with generating Results. Customer agrees that Woliba may export any Personal Information provided by Customer hereunder, either with respect to Company Data, or with respect to individuals’ business contact information (including names and e-mail addresses) to any country in which Woliba does business, and Woliba shall process such Personal Information in compliance with the applicable Data Protection Laws and solely on the instructions of Customer. Personal Information will be processed and used solely in connection with the business relationship between Woliba and Customer, and may be provided to contractors, business partners, and assignees of Woliba for uses consistent with business activities concerning Customer, subject to the confidentiality and other provisions contained herein. Notwithstanding the foregoing, Customer acknowledges that Woliba is constantly improving the Woliba Software Services for the benefit of all Woliba customers including Customer and, in order to do so, may utilize Company Data for the purpose of: (a) undertaking internal research and development, and (b) assessing and improving the Woliba Software Services. In addition, it is understood that Woliba generates anonymized summary metrics regarding how its customers use the Woliba Software Services, including outputs and potential impacts, and that Woliba may use such summary metrics, to generate benchmarking reports. For this purpose, the characteristics of any individual persons or entities are not identifiable.',
  },
  {
    title: 'Woliba Ownership',
    content: 'As between Customer and Woliba, Woliba will retain all right, title and interest in and to all proprietary rights with respect to the Software and Services including, without limitation, the Woliba Software Services and the related Professional Services provided by Woliba, together with any Documentation, training materials, report templates, whitepapers, or deliverables provided by Woliba under the Agreement, and any suggestions for modifications or improvements to the Woliba Software Services provided by Customer. Further, Woliba will also own any and all summary metrics regarding how its customers’ use the Woliba Software Services, provided that the characteristics of any individual persons or entities are not identifiable.',
  },
  {
    title: 'Limited Rights',
    content: 'Woliba reserves all rights and licenses in and to the Woliba Software Services not expressly granted to Customer under this Agreement. Title to the Woliba technology and the technology of its third party licensors is not conveyed hereunder and is merely licensed on a subscription xxx basis.',
  },
  {
    title: 'Confidential Information',
    content: '“Confidential Information” means the written, oral and visual information, about past, present or future products, software, or marketing and business data, or administrative, management, financial, marketing, manufacturing, or development activities of a party which is marked or designated as confidential or proprietary, or which is disclosed in circumstances of confidence and would be understood by the parties, exercising reasonable business judgment, to be confidential or proprietary, including without limitation information viewed or learned by the receiving party during a visit to the disclosing party’s facilities. Company Data and Results are deemed the Confidential Information of Customer. The functionality accessed through the Woliba Software Services, including the logic, APIs, formulas, and algorithms contained in or underlying the Woliba Software Services, whether or not ascertainable by authorized use and inspection of the Woliba Software Services and the Documentation, is Woliba Confidential Information.',
  },
  {
    title: 'Use and Nondisclosure Obligations',
    content: 'The receiving party will (a) hold the disclosing party’s Confidential Information in confidence and take reasonable precautions to protect such Confidential Information (including, without limitation, all precautions the receiving party employs with respect to its similar proprietary materials), (b) not disclose any such Confidential Information or any information derived therefrom to any third person except as authorized hereunder, (c) not make any use of such Confidential Information except to carry out its rights and obligations under this Agreement, and (d) not copy (except as necessary to carry out its rights and obligations under this Agreement). Any employee given access to any such Confidential Information must have a legitimate “need to know” and must be bound by confidentiality obligations no less protective of the disclosing party than this Section 6. The specific terms of this Agreement and any Orders or SOWs entered into pursuant hereto shall be deemed to be Confidential Information.',
  },
  {
    title: 'Authorized Disclosures',
    content: 'The receiving party may disclose Confidential Information pursuant to the order or requirement of a court, administrative agency, or other governmental body; to the extent allowed by law, the receiving party must give reasonable notice to the disclosing party to allow the disclosing party to contest such order or requirement or seek confidentiality treatment. Each party may disclose the terms and conditions of this Agreement on a confidential basis (a) to legal or financial advisors; (b) pursuant to a registration report or exhibits thereto required to be filed with the Securities and Exchange Commission, listing agency or any state securities commission, or any other associated filings; or (c) in connection with any financing transaction or due diligence inquiry. Notwithstanding anything herein to the contrary, each party (and each employee, representative, or other agent of such party) may disclose to any and all persons, without limitation of any kind, the tax treatment and tax structure of the transaction represented by this Agreement and all materials of any kind (including opinions and other tax analyses) that are provided to the party relating to such tax treatment and tax structure.',
  },
  {
    title: 'Exceptions',
    content: 'Without granting any right or license, the disclosing party agrees that Section 6.5 does not apply to any information that the receiving party can demonstrate (a) is or (through no improper action or inaction by the receiving party or any affiliate, agent, employee, consultant or contractor) becomes generally available to the public, or (b) was properly in its possession or known by it without restriction prior to receipt from the disclosing party, or (c) was rightfully disclosed to it by a third party without restriction, or (d) was independently developed without use of or reference to any Confidential Information of the disclosing party. Notwithstanding any other provision of this Agreement, each party will be free to use for itself and for others in any manner the general knowledge, skill or experience acquired by that party in the course of this Agreement, including using that knowledge as it relates to any present or future customer, vendor or other business partner.',
  },
  {
    title: 'Consultants and Subcontractors',
    content: 'The receiving party may disclose the disclosing party’s Confidential Information to the receiving party’s consultants and subcontractors who have a legitimate “need to know” so long as: (a) such contractor is not a competitor of the other party; (b) such contractor is informed of the confidential status of the Confidential Information; (c) such contractor is similarly bound in writing with terms and conditions no less protective of the disclosing party than this Section 6, and (d) the receiving party agrees to be liable to the other party for any breach by its contractors to the same extent as if such breach was committed by the receiving party. The receiving party will not disclose the disclosing party’s trade secrets to any of its consultants or subcontractors without the disclosing party’s prior express written consent.',
  },
];

const securityProtection = [
  {
    title: 'Security and Controls for the Protection of Company Data',
    content: 'Woliba shall maintain appropriate technical and organizational measures for protection of the security (including protection against unauthorized or unlawful processing and against accidental or unlawful destruction, loss or alteration or damage, unauthorized disclosure of, or access to, Company Data), confidentiality and integrity of Company Data, as set forth in Schedule C attached hereto. Woliba regularly monitors compliance with these measures. Woliba will not materially decrease the overall security of a particular Ordered Software Service during its applicable Subscription Term.',
  },
  {
    title: 'Privacy Policy',
    content: 'In providing the Ordered Software Services and performing the Services, Woliba will comply with the Woliba Privacy Policy. The Woliba Privacy Policy is subject to change at Woliba’s discretion; however, Woliba policy changes will not result in a material reduction in the level of protection provided for any Personal Information included in the Company Data.',
  },
  {
    title: 'Data Processing Addendum',
    content: 'If Customer’s use of an Ordered Software Service involves processing “personal data” pursuant to the GDPR and/or transferring such personal data outside the EEA or Switzerland to any country not deemed by the European Commission as providing an adequate level of protection for personal data, the terms of the data processing addendum attached hereto as Schedule D (the “DPA”) shall apply to such personal data and be incorporated into the Agreement. For the purposes of the GDPR, as between Customer and Woliba, the parties agree that Customer shall at all times be the data controller and Woliba shall be the data processor with respect to the processing of Customer personal data in connection with Customer’s use of an Ordered Software Service. It is understood that Woliba may engage third party sub-processors to process personal data on Woliba’s behalf in order to provide the Woliba Software Services subject to the DPA and the terms of this Agreement, including Section 3.4(b) hereof.\n\t(a) On behalf of itself and its Users, and as the data controller of Customer personal data, Customer represents and warrants to Woliba that its and its Users’ provision of personal data to Woliba and instructions for processing such personal data in connection with the Services shall comply with all Data Protection Laws.\n\t(b) In accordance with applicable Data Protection Laws, Woliba shall take all commercially reasonable measures to protect the security and confidentiality of Customer personal data against any accidental or illicit destruction, alteration or unauthorized access or disclosure to third parties.',
  },
  {
    title: 'Third-Party Certifications and Audits',
    content: 'Woliba has obtained the third-party certifications and audits set forth in Schedule C. Upon Customer’s written request at reasonable intervals, and subject to the confidentiality obligations set forth in the Agreement, Woliba shall make available to Customer (or Customer’s independent, third-party auditor that is not a competitor of Woliba) a copy of Woliba\'s then most recent third-party audits or certifications, as applicable.',
  },
];

const warrantiesLimitations = [
  {
    title: 'By Customer',
    content: 'Customer represents and warrants that its provision of the Company Data will not violate any applicable laws, including without limitation any applicable data privacy, data protection, or data processing laws or regulations. Customer acknowledges and agrees that it is solely responsible for obtaining, and represents and warrants to Woliba that it shall obtain, any and all required consents in connection with all Company Data it provides to Woliba.',
  },
  {
    title: 'Services Warranty',
    content: 'Woliba will perform all Services in a professional, workmanlike manner in compliance with industry standards. Customer’s sole remedy for breach of this warranty shall be for Woliba to re-perform the non-conforming Services.',
  },
  {
    title: 'Software Services Warranty',
    content: 'Woliba warrants to Customer during the applicable Subscription Term of a particular Order that each Ordered Software Service subscribed for in that Order will perform in all material respects the functions described in its then-current Documentation. This warranty does not apply to performance issues of an Ordered Software Service (a) caused by factors outside of Woliba’s reasonable control; (b) that result from inaccurate or incomplete Company Data not in material conformity with the applicable DIOS xxx for the particular Ordered Software Service; or (c) that otherwise result from Customer’s data structure, operating environment or Access Equipment. If an Ordered Software Service fails to operate as warranted in this Section 8.3 and Customer notifies Woliba in writing of the nature of the non-conformance (“Notice”), Woliba will make commercially reasonable efforts to promptly repair or replace the non-conforming Ordered Software Service without charge. If, after a reasonable opportunity to cure, Woliba does not remedy the non-conformance, Customer may no later than sixty (60) days after giving the Notice, terminate the impacted Order and receive a refund of any prepaid Subscription Fees relating to the non- conforming Ordered Software Service for the period following the date of Notice. Woliba shall have no obligation with respect to a warranty claim unless notified of such claim within thirty (30) days of the first instance of any material functionality problem. The foregoing provides Customer’s sole and exclusive remedy for breach of the exclusive warranty in this Section 8.3, it being understood that this shall not limit Customer’s other remedies under the Agreement for breach of contract or other claims.',
  },
  {
    title: 'WARRANTY DISCLAIMER',
    content: 'EXCEPT FOR THE WARRANTIES EXPRESSLY DESCRIBED IN THIS AGREEMENT, WOLIBA MAKES NO OTHER WARRANTIES WITH RESPECT TO THE SERVICES, EXPRESS, IMPLIED OR STATUTORY, AND DISCLAIMS ANY IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR ANY WARRANTY ARISING FROM COURSE OF DEALING, USAGE OR TRADE PRACTICE. WOLIBA DOES NOT WARRANT THAT THE WOLIBA SOFTWARE SERVICES AND THE DATA STORED THROUGH THE USE OF SUCH WOLIBA SOFTWARE SERVICES ARE NOT SUSCEPTIBLE TO INTRUSION, ATTACK OR COMPUTER VIRUS INFECTION. CUSTOMER HAS FULL RESPONSIBILITY FOR HOW IT ELECTS TO USE THE WOLIBA SOFTWARE SERVICES, INCLUDING ANY AND ALL INTERPRETATIONS AND DECISIONS MADE BY CUSTOMER IN CONNECTION THEREWITH.',
  },
  {
    title: 'LIMITATION OF LIABILITY',
    content: '(a) TOTAL LIABILITY. NOTWITHSTANDING ANYTHING ELSE IN THIS AGREEMENT OR OTHERWISE, EXCEPT FOR (i) LIABILITIES ARISING UNDER SECTIONS 5.5 AND 6, (ii) CLAIMS FOR INDEMNIFICATION UNDER THIS AGREEMENT, AND/OR (iii) CUSTOMER’S PAYMENT OBLIGATIONS UNDER THIS AGREEMENT, NEITHER PARTY NOR ITS THIRD PARTY SUPPLIERS AND LICENSORS WILL BE LIABLE TO THE OTHER FOR ANY LOSS, DAMAGE OR LIABILITY UNDER OR IN CONNECTION WITH THIS AGREEMENT, UNDER ANY THEORY OF LIABILITY, FOR ANY AMOUNTS, IN THE AGGREGATE, IN EXCESS OF THE FEES PAID OR PAYABLE TO WOLIBA FOR THE SERVICES UNDER THE ORDER THAT IS THE SUBJECT OF THE CLAIM IN THE IMMEDIATELY PRECEDING TWELVE (12) MONTH PERIOD.\n(b) EXCLUSION OF DAMAGES. NEITHER PARTY NOR ITS THIRD PARTY SUPPLIERS AND LICENSORS WILL BE LIABLE TO THE OTHER UNDER OR IN CONNECTION WITH THIS AGREEMENT UNDER ANY CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHER LEGAL OR EQUITABLE THEORY, AND WHETHER OR NOT SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE, FOR ANY PUNITIVE, SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES, WHETHER OR NOT SUCH LOSSES OR DAMAGES ARE FORESEEABLE, INCLUDING WITHOUT LIMITATION LOSS OF PROFITS, LOSS OR CORRUPTION OF DATA OR INTERRUPTION OF USE.\n(c) NOTHING IN THIS AGREEMENT WILL LIMIT A PARTY’S LIABILITY FOR GROSS NEGLIGENCE, FRAUD OR WILFULL MISCONDUCT OR FOR DEATH OR PERSONAL INJURY CAUSED BY A PARTY’S NEGLIGENCE UNDER THIS AGREEMENT.\n(d) FAILURE OF ESSENTIAL PURPOSE. The limitations specified in this Section 8 will survive and apply even if any limited remedy specified in this Agreement is found to have failed of its essential purpose.',
  },
];

const indemnification = [
  {
    title: 'Infringement Indemnity',
    content: 'Woliba will defend any action brought against Customer to the extent that it is based upon a claim that the Woliba Software Service, as provided by Woliba to Customer under this Agreement and used within the scope of this Agreement, infringes any third party copyright or U.S. patent, and will pay any costs, damages and reasonable attorneys\' fees attributable to such claim that are awarded against Customer or agreed upon by Woliba in settlement, provided that Customer: (a) promptly notifies Woliba in writing of the claim; (b) grants Woliba sole control of the defense and settlement of the claim; and (c) provides Woliba with all assistance, information and authority required for the defense and settlement of the claim. Woliba will not reimburse Customer for any expenses incurred without the prior written approval of Woliba. Customer may engage counsel of its choice at its own expense. Woliba will have no right to bind Customer to terms other than the terms and conditions in this Agreement or admit liability by Customer in any claim, or settlement thereof, without Customer’s prior written consent, which will not to be unreasonably withheld or delayed.',
  },
  {
    title: 'Injunctions',
    content: 'If Customer\'s use of any of the Woliba Software Service hereunder is, or in Woliba\'s reasonable opinion is likely to be, enjoined due to the type of infringement specified in Section 9.1 above, or if a claim is brought against Customer due to the type of infringement specified in Section 9.1 above, then Woliba may, at its sole option and expense: (a) procure for Customer the right to continue using such Woliba Software Service under the terms of this Agreement; (b) replace or modify such Woliba Software Service so that it is non-infringing and substantially equivalent or better in function to the infringing Woliba Software Service; or (c) if options (a) and (b) above cannot be accomplished despite Woliba\'s commercially reasonable efforts, then Woliba may terminate the applicable Order and remit to Customer any pre-paid Subscription Fees under that Order for the remainder of the Subscription Term after the termination.',
  },
  {
    title: 'Sole Remedy',
    content: 'THE FOREGOING PROVISIONS OF THIS SECTION 9 SET FORTH WOLIBA’S SOLE AND EXCLUSIVE OBLIGATIONS, AND CUSTOMER’S SOLE AND EXCLUSIVE REMEDIES, WITH RESPECT TO INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS.',
  },
];

const termTermination = [
  {
    title: 'Term',
    content: 'Unless earlier terminated in accordance with the terms hereof, this Agreement will commence on the Effective Date and will continue until 60 days past the date that there are no outstanding Orders or SOWs (the “Term”). A renewal term will be deemed commenced by the execution of an Order or the payment of an invoice for any renewal term.',
  },
  {
    title: 'Subscription Terms',
    content: 'Subscriptions to use an Ordered Software Service begin on the Order Effective Date specified in the relevant Order and continue for the period set forth therein (each a “Subscription Term”), unless terminated earlier in accordance with this Agreement.',
  },
  {
    title: 'Termination for Breach',
    content: 'Either party may terminate the Agreement or a specific Order or SOW by written notice if the other party commits a material breach of the Agreement or such Order or SOW and such breach is not cured within thirty (30) days of receipt of written notice thereof. In addition, either party may terminate this Agreement (including all outstanding Orders and SOWs) immediately upon written notice to the other party in the event (a) the other party files a petition for bankruptcy or is adjudicated a bankrupt; (b) a petition in bankruptcy is filed against the other party and such petition is not dismissed within sixty (60) calendar days; (c) the other party becomes insolvent or makes an assignment for the benefit of its creditors or an arrangement for its creditors pursuant to any bankruptcy or other similar law; (d) the other party discontinues its business; or (e) a receiver is appointed for the other party or its business. If this Agreement terminates or lapses and there are still one or more outstanding Orders or SOWs still in effect, the terms of this Agreement shall continue to apply to such outstanding Orders and SOWs.',
  },
  {
    title: 'Effect of Termination of Individual Orders',
    content: 'In the event of termination of an Order or SOW, this Agreement shall continue to apply to any other Orders or SOWs still in effect. The foregoing notwithstanding, Customer understands that, immediately following the expiration or termination of a particular Order, Woliba shall decommission all hosting environments established in connection with such Order and shall delete all Company Data provided in connection with such Order.',
  },
  {
    title: 'Effect of Termination',
    content: 'Upon termination of the Agreement or a specific Order or SOW, as applicable, (a) all access rights to the applicable Woliba Software Services shall immediately terminate and Woliba shall deactivate and terminate access to all such Woliba Software Services, (b) Woliba shall immediately cease providing any applicable Professional Services, (c) each party shall promptly destroy or return to the other party all copies and originals of documents and other materials that contain or embody the other party’s Confidential Information that are in its possession and relevant to the terminated Agreement or specific terminated Order or SOW, and (d) all forward obligations of the parties shall cease with respect to the terminated Agreement or specific terminated Order or SOW, except that (i) Customer shall be obliged to pay all amounts that became payable prior to termination under any outstanding Orders and SOWs, and (ii) the provisions of Sections 4, 5, 6, 7, 8, 9, 10 and 13 will survive termination of the Agreement.',
  },
];

const miscellaneous = [
  {
    title: 'Assignment',
    content: 'Neither party may assign, transfer or delegate any of the rights or obligations under this Agreement or a particular Order or SOW (including, without limitation interests or claims relating thereto) without the prior written consent of the other party; provided that either party may assign this Agreement and all Orders and SOWs to an entity that has acquired all or substantially all of its assets or capital stock as successor to the business and, provided further, that any assignment by Customer will shall be subject to the scope limitations for a particular Woliba Software Service set forth in the applicable Order (including but not limited to Designated Business Unit, Revenue limitations and User limitations, if any).',
  },
  {
    title: 'Waiver and Amendment',
    content: 'No modification, amendment or waiver of any provision of this Agreement will be effective unless in writing and signed by the parties duly authorized representatives. The failure by either party to enforce any provision of this Agreement will not constitute a waiver of future enforcement of that or any other provision.',
  },
  {
    title: 'Governing Law',
    content: 'This Agreement will be governed by the laws of the State of Texas and the United States, without reference to conflict of laws principles and without reference to UCITA (the Uniform Computer Information Transactions Act) as it may be enacted in the applicable jurisdiction.',
  },
  {
    title: 'Notices',
    content: 'All notices, demands or consents required or permitted under this Agreement will be in writing. Notice will be considered effective on the earlier of actual receipt or: (a) the business day following transmission if sent by facsimile followed by written confirmation; (b) one business day (two business days for international addresses) after posting when sent via an express commercial courier; or (c) five days after posting when sent via certified United States mail. Notice will be sent to the address for each party set forth on the first page of this Agreement, or at such other address as will be given by either party to the other in writing. Notices to Woliba will be addressed to the attention of: Contract Administrator.',
  },
  {
    title: 'Independent Contractors',
    content: 'The parties to this Agreement are independent contractors and this Agreement will not establish any relationship of partnership, joint venture, employment, franchise, or agency between the parties. Neither party will have the power to bind the other or incur obligations on the other\'s behalf without the other\'s prior written consent.',
  },
  {
    title: 'Severability',
    content: 'If for any reason a court of competent jurisdiction finds any provision of this Agreement invalid or unenforceable, that provision of the Agreement will be enforced to the maximum extent permissible and the other provisions of this Agreement will remain in full force and effect.',
  },
  {
    title: 'Force Majeure',
    content: 'Except for Customer’s obligations to pay Woliba hereunder, neither party will be liable to the other party for any failure or delay in performance due to causes beyond its reasonable control, including but not limited to, fire, earthquake, war, riot, terrorism, computer intrusion, act of God or governmental action.',
  },
  {
    title: 'Attorneys\' Fees',
    content: 'If any action is brought to enforce any provision of this Agreement or to declare a breach of this Agreement, then the prevailing party will be entitled to recover, in addition to any other amounts awarded, reasonable legal and other related costs and expenses, including attorney\'s fees, incurred thereby. For purposes of this section only, "prevailing party" means the party that prevails on a majority of causes of action in such dispute.',
  },
  {
    title: 'Non-Solicitation',
    content: 'During the Term and for a period of one year thereafter, neither Woliba nor Customer shall directly solicit employment of any employee or any contractor of the other without the prior written consent of the other party; provided, this provision will only apply to a party’s contractors with whom the other party comes into direct contact during the course of performing work or services under this Agreement. This Section shall not restrict the right of either party to solicit or recruit generally.',
  },
  {
    title: 'Precedence',
    content: 'To the extent there is a conflict between the terms in the main text of this Agreement and any terms in an Order and the other referenced documents, then the following precedence will apply: (1) the Order; (2) the main text of the Agreement; and (3) the other referenced documents.',
  },
  {
    title: 'Entire Agreement',
    content: 'This Agreement, including all Schedules and referenced documents, contains the complete understanding and agreement of the parties and supersedes all prior or contemporaneous agreements or understandings, oral or written, relating to the subject matter herein. The headings used in this Agreement are for reference only and do not change the meaning of any provision of this Agreement, and will not be used in interpretation of any provision of this Agreement.',
  },
];

class TermsOfService extends React.Component {
  
  renderSmallTitles = (title, content, bool = false) => (
    <SmallHeader disableWeight={1}>
      <ParagraphWrapper italic={1} underline={1}>{title}</ParagraphWrapper>
      <ParagraphWrapper>{!bool && '.'}&nbsp; {content} </ParagraphWrapper>
    </SmallHeader>
  );
  
  renderList = (arr, bool = false) => (
    <OList>
      {arr.map((obj, index) => (
        <List key={index} disableWeight={1}>{this.renderSmallTitles(obj.title, obj.content, bool)}
          <p>&nbsp;</p>
        </List>
      ))}
    </OList>
  );

  render() {
    const { history } = this.props;
    const authToken = (checkPermission() && localStorage && localStorage.getItem('AUTH_TOKEN')) ? true : false;   
    
    return(
      <Layout>
        <NavigationBarV2
          history={history}
          showInternalLinks={false}
          isUser={authToken}
        />
        <Section>
          <Header>Terms Of Service</Header>
          <ContentWrapper>
            <SmallHeader textCenter={1}>MASTER SOFTWARE SUBSCRIPTION AND SERVICES AGREEMENT</SmallHeader>
            <p>&nbsp;</p>
            <SmallHeader disableWeight={1}>{'This Master Software Subscription and Services Agreement (“Agreement”) is made as of the date accepted (the “Effective Date”), between Woliba Incorporated ("Woliba"), with offices at 1460 Broadway, New York, NY. 10019 and the customer accepting these terms (“Customer”).'}</SmallHeader>
            <p>&nbsp;</p>
            <SmallHeader disableWeight={1}>{'This Agreement sets forth the terms and conditions under which the parties agree that Customer may, pursuant to one or more separately executed Orders, purchase a subscription to utilize certain Woliba Software Services and related Professional Services listed on the applicable Order. This Agreement includes the below Terms and Conditions, all Schedules and attachments that reference this Agreement and the Woliba Privacy Policy provided at https://trainingamigo.com/privacy-policy as it may be updated from time to time (the “Privacy Policy”).'}</SmallHeader>
            <p>&nbsp;</p>
            <SmallHeader disableWeight={1}>{'By selecting “Accept,” Customer agrees to be bound by the terms and conditions of this Agreement.'}</SmallHeader>
            <p>&nbsp;</p>
            <OList>
              <List><SmallHeader>CERTAIN DEFINITIONS</SmallHeader>
                <p>&nbsp;</p>
                <SmallHeader disableWeight={1}>{'The following terms have the following meanings when used in the Agreement:'}</SmallHeader>
                <p>&nbsp;</p>
                { this.renderList(certainDefinitions, 1) }
                <p>&nbsp;</p>
              </List>
              <List><SmallHeader>ORDERING PROCESS</SmallHeader>
                <p>&nbsp;</p>
                { this.renderList(orderingProcess) }
                <p>&nbsp;</p>
              </List>
              <List><SmallHeader>SUBSCRIPTION AND SERVICES</SmallHeader>
                <p>&nbsp;</p>
                { this.renderList(subscriptionServices) }
                <p>&nbsp;</p>
              </List>
              <List><SmallHeader>FEES AND PAYMENT TERMS</SmallHeader>
                <p>&nbsp;</p>
                { this.renderList(feesPaymentTerms) }
                <p>&nbsp;</p>
              </List>
              <List><SmallHeader>CUSTOMER OBLIGATIONS</SmallHeader>
                <p>&nbsp;</p>
                { this.renderList(customerObligations) }
                <p>&nbsp;</p>
              </List>
              <List><SmallHeader>OWNERSHIP, CONFIDENTIAL INFORMATION AND ATTRIBUTION</SmallHeader>
                <p>&nbsp;</p>
                { this.renderList(ownershipAttribution) }
                <p>&nbsp;</p>
              </List>
              <List><SmallHeader>SECURITY AND DATA PROTECTION</SmallHeader>
                <p>&nbsp;</p>
                { this.renderList(securityProtection) }
                <p>&nbsp;</p>
              </List>
              <List><SmallHeader>WARRANTIES AND LIMITATIONS</SmallHeader>
                <p>&nbsp;</p>
                { this.renderList(warrantiesLimitations) }
                <p>&nbsp;</p>
              </List>
              <List><SmallHeader>INDEMNIFICATION</SmallHeader>
                <p>&nbsp;</p>
                { this.renderList(indemnification) }
                <p>&nbsp;</p>
              </List>
              <List><SmallHeader>TERM AND TERMINATION</SmallHeader>
                <p>&nbsp;</p>
                { this.renderList(termTermination) }
                <p>&nbsp;</p>
              </List>
              <List><SmallHeader>INSURANCE</SmallHeader>
                <p>&nbsp;</p>
                <SmallHeader disableWeight={1}>{'During the term of this Agreement, Woliba shall, at its sole cost and expense, secure and maintain the following types of insurance and amounts: (a) Workers’ Compensation insurance or state approved self-insurance in amounts as required by the applicable statutes; (b) Professional Liability Insurance or similar coverage (which shall include Privacy/Network/Cyber Security coverage) in an amount of not less than $1,000,000 each claim and $2,000,000 aggregate for bodily injury, property damage and consequential financial loss for errors and omissions pertaining to any and the Professional Services provided to Customer; (c) Commercial General Liability insurance providing coverage for bodily injury and property damage and coverage for product and completed operations, personal and advertising injury, and contractual liability in a minimum amount of $1,000,000 per occurrence and $2,000,000 aggregate; (d) umbrella / excess liability coverage in the amount of $5,000,000 per occurrence and $5,000,000 aggregate; and (e) hired and non-owned Automobile Liability insurance for bodily injury and property damage with a combined single limit of $1,000,000. All insurance maintained by Woliba shall be with duly licensed or approved non-admitted insurers in with an “A.M. Best” rating of not less than “A-” and Woliba must provide notice, in accordance with policy provisions, prior to any cancellation, or any material change or reduction of the coverages shown herein. Woliba shall furnish to Customer certificates of insurance reflecting policies in force upon the reasonable request of Customer.'}</SmallHeader>
                <p>&nbsp;</p>
              </List>
              <List><SmallHeader>PUBLICITY</SmallHeader>
                <p>&nbsp;</p>
                <SmallHeader disableWeight={1}>{'Customer grants to Woliba permission during the Term to list Customer’s name and to display Customer’s logo in its standard customer lists, including on the Woliba website, and in presentations and materials Woliba uses in discussions with current and prospective customers subject to Woliba complying with Customer’s trademark guidelines as may be provided to Woliba from time to time. This permission is for use of the name and logo only, and such use shall not be combined with any statements attributable to or concerning Customer without Customer’s express written consent, which may be withheld in Customer’s sole discretion. In addition, Woliba may issue a press release announcing the relationship formed by the Agreement, subject to the prior approval of the Customer, not to be unreasonably withheld or delayed. Thereafter, if a party wishes to issue a press release concerning the existence or terms of this Agreement or the relationship between the parties, then such party shall submit the statement to the other party for review and approval, which a party may withhold in its sole discretion.'}</SmallHeader>
                <p>&nbsp;</p>
              </List>
              <List><SmallHeader>MISCELLANEOUS</SmallHeader>
                <p>&nbsp;</p>
                { this.renderList(miscellaneous) }
                <p>&nbsp;</p>
              </List>
            </OList>
          </ContentWrapper>
        </Section>
        <LandingFooterPanel />
      </Layout>  
    )
  }
}

TermsOfService.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(TermsOfService);
